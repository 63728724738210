const React = require("react");
const { QueryClientProvider } = require("@tanstack/react-query");
const { QueryClient } = require("@tanstack/react-query");
const AppProvider = require("./src/context/AppProvider").default;

const queryClient = new QueryClient()

exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        {element}
      </QueryClientProvider>
    </AppProvider>
  )
};
